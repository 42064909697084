import { AdminGetOneResponse, AdminVideoTagsListResponse } from 'models';
import { VideoItemBaseInfoFormValues, VideoItemConnectionsFormValues } from 'pages/Video/types';

export const mapVideoForFrontend = (videoData: AdminGetOneResponse, tags: AdminVideoTagsListResponse[]) => {
  const { title, isHidden, priority, quality } = videoData;
  return {
    title,
    isHidden,
    priority,
    quality,
    rolesIds: videoData.roles.map(({ name, id }) => {
      return { label: name, key: id, value: name };
    }),
    areasIds: videoData.areas.map((item) => item.id),
    areasNames: videoData.areas.map((area) => {
      return { label: area.name, key: area.id, value: area.name };
    }),
    authorId: { label: videoData.author?.name, value: videoData?.author?.id },
    tagsIds: tags.map(({ name, id }) => {
      return { label: name, key: id, value: name };
    }),
  };
};

export const mapBaseVideoInfoForBackend = (videoFields: VideoItemBaseInfoFormValues) => {
  const { isHidden, priority, quality, authorId } = videoFields;

  return {
    isHidden,
    priority,
    quality,
    authorId: authorId?.value ? authorId.value : undefined,
  };
};

export const mapRolesVideoInfoForBackend = (videoFields: VideoItemConnectionsFormValues) => {
  const { rolesIds } = videoFields;

  return {
    rolesIds: rolesIds.map((item) => item.key),
  };
};

export const mapTagsVideoInfoForBackend = (videoFields: VideoItemConnectionsFormValues) => {
  const { tagsIds } = videoFields;

  return {
    tagsIds: tagsIds.map((item) => item.key),
  };
};
