import { Spin } from 'antd';
import { useAuthorsBackendActions } from 'client/hooks/authors';
import { useParams } from 'react-router-dom';
import { ClientErrorException } from '../../components/ClientErrorException';
import { AuthorContent } from './AuthorContent';

export const Author = () => {
  const { id } = useParams<{ id: string }>();
  const isCreationMode = id === 'new';

  const {
    info: { authorData, error, loading, isLoading },
    actions: { handleDeleteAuthor, onSave },
  } = useAuthorsBackendActions({ isCreationMode, id });

  if (error) {
    return <ClientErrorException errorCode={error.request.status} />;
  }

  return (
    <Spin spinning={isLoading || loading}>
      {authorData && !loading && (
        <AuthorContent
          authorData={authorData}
          onDeleteButtonClick={handleDeleteAuthor}
          onSave={onSave}
          isCreationMode={isCreationMode}
        />
      )}
    </Spin>
  );
};
