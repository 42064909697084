import { getFeedbackEndpointUrl } from 'client/endpoints/feedback';
import { useCallback, useContext, useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import { UserContext } from 'contexts/UserContext';
import { notification } from 'antd';
import { AdminGetListFeedbacksResponseDto, AdminUserInFeedbackResponse } from 'models';

export type PaginationParams = {
  limit: number;
  offset: number;
};

export type SortParams = {
  orderBy?: string;
  sortBy?: string;
};

type AdminGetListFeedbacksResponseWithUser = Omit<AdminGetListFeedbacksResponseDto, 'user'> & {
  user: AdminUserInFeedbackResponse;
};

const getName = (feedback: AdminGetListFeedbacksResponseWithUser) => {
  if (feedback.user.name !== null && feedback.user.phone !== null) {
    return `${feedback.user.name} ${feedback.user.phone}`;
  }
  if (feedback.user.name === null && feedback.user.phone !== null)
    return `(Вход через сторонний сервис) ${feedback.user.phone}`;
  if (feedback.user.name !== null && feedback.user.phone === null)
    return `${feedback.user.name} (Вход через сторонний сервис)`;
  return `(Вход через сторонний сервис)`;
};

const mapFeedbacks = (feedbacks: Array<AdminGetListFeedbacksResponseDto>) => {
  return feedbacks?.map((feedback) => ({
    ...feedback,
    name: feedback.user ? getName(feedback as AdminGetListFeedbacksResponseWithUser) : 'Аноним',
  }));
};

export const useFeedback = () => {
  const [paginationData, setPaginationData] = useState<PaginationParams>({
    limit: 10,
    offset: 0,
  });
  const [sortData, setSortData] = useState<SortParams>({ orderBy: undefined, sortBy: undefined });
  const [totalCount, setTotalCount] = useState(0);
  const isAuthorized = typeof useContext(UserContext).user !== 'undefined';

  const [{ loading: isFeedbacksLoading }, getFeedbacks] = useAxios<
    BaseResponse<Array<AdminGetListFeedbacksResponseDto>, PageMeta>
  >(
    {
      url:
        getFeedbackEndpointUrl() +
        `limit=${paginationData.limit}&offset=${paginationData.offset}${
          sortData.orderBy ? `&sortBy=${sortData.sortBy}&orderBy=${sortData.orderBy}` : ''
        }`,
      method: 'GET',
    },
    { manual: true },
  );
  const [feedbacks, setFeedbacks] = useState<Array<AdminGetListFeedbacksResponseDto>>([]);

  const getInitialFeedbacks = useCallback(async () => {
    try {
      const {
        data: { data: feedbacks, meta },
      } = await getFeedbacks();
      setTotalCount(meta.total ?? 0);
      setFeedbacks(mapFeedbacks(feedbacks));
    } catch (e) {
      notification.error({ message: 'Что-то пошло не так.' });
    }
  }, [getFeedbacks]);

  const updatePaginationInfo = useCallback(({ limit, offset }: PaginationParams) => {
    setPaginationData({ limit, offset });
  }, []);

  const updateSortInfo = useCallback(({ field, order }: { field: string; order?: string }) => {
    setSortData({ orderBy: order, sortBy: field });
  }, []);

  useEffect(() => {
    if (isAuthorized) {
      getInitialFeedbacks();
    }
  }, [isAuthorized, paginationData, getInitialFeedbacks]);

  return {
    getCities: getInitialFeedbacks,
    feedbacks,
    isLoading: isFeedbacksLoading,
    updatePaginationInfo,
    updateSortInfo,
    totalCount,
  };
};
