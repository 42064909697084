import { PageTitle } from '../../components/PageTitle';
import { NAVIGATION } from '../../paths';
import { Image, Layout, Table, TableProps } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { useCities } from 'client/hooks/cities';
import NoImage from '../../images/noimage.png';

interface CityDataType {
  key: React.Key;
  title: string;
}

export const Cities = () => {
  const push = useNavigate();
  const { cities: needs, updatePaginationInfo, totalCount } = useCities();

  const tableData: Array<CityDataType> = needs.map((item) => {
    return {
      key: item.id,
      title: item.name,
      image: item.image,
      entityCount: item.entityCount,
    };
  });
  const onChange: TableProps<CityDataType>['onChange'] = ({ current, pageSize = 10 }) => {
    if (current !== undefined && pageSize !== undefined) {
      const offset = current === 1 ? '0' : (current - 1) * pageSize;
      updatePaginationInfo({ offset: +offset, limit: pageSize });
    }
  };

  const columns: ColumnsType<CityDataType> = [
    {
      width: '10%',
      title: 'Обложка',
      dataIndex: 'image',
      render: (image) => {
        return <Image preview={false} width={80} height={80} src={image ?? NoImage} />;
      },
    },
    {
      title: 'Список городов',
      dataIndex: 'title',
      sortDirections: ['descend', 'ascend'],
      render: (title: string) => {
        return (
          <p title={title} className={'m-0 text-overflow w-50 overflow-hidden'}>
            {title}
          </p>
        );
      },
    },
    {
      width: '20%',
      align: 'center',
      title: 'Количество связанных мест',
      dataIndex: 'entityCount',
      render: (entityCount) => {
        return <p>{entityCount}</p>;
      },
    },
  ];

  return (
    <Layout>
      <PageTitle
        title="Города"
        wrapperClassName="mb-24"
        titleClassName={'mb-24'}
        onAddButtonClick={() => push(`${NAVIGATION.cities}/new`)}
      />

      <Content>
        <Table
          pagination={{
            total: totalCount,
            pageSizeOptions: ['10', '20'],
            defaultPageSize: 10,
          }}
          rowClassName={'cursor-pointer'}
          onRow={(record) => {
            return {
              onClick: () => {
                push(`${NAVIGATION.cities}/${record.key}`);
              },
            };
          }}
          columns={columns}
          dataSource={tableData}
          onChange={onChange}
        />
      </Content>
    </Layout>
  );
};
