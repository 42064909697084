import { FC } from 'react';
import styles from './styles.module.css';
import { Avatar, Button, Spin, Typography, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { UploadRequestOption } from 'rc-upload/lib/interface';

type UploadImageProps = {
  isLoading: boolean;
  src?: string;
  customRequest: (params: UploadRequestOption) => void;
};

export const UploadImage: FC<UploadImageProps> = ({ isLoading, src, customRequest }) => {
  return (
    <div className="d-flex gap-8 flex-items-center">
      <div className={styles.loadingWrapper}>
        <div className={`${styles.spinContainer} ${!isLoading ? styles.hiddenSpinner : ''}`}>
          <Spin />
        </div>
        <div className={`${styles.loadingComponent} ${!isLoading ? styles.hiddenContainer : ''}`}>
          <Avatar
            size={88}
            shape="square"
            src={src}
            style={{ border: '1px solid #434343', backgroundColor: 'transparent' }}
          />
        </div>
      </div>

      <div className="d-flex flex-column gap-8">
        <Typography.Text className="text-gray-7">Используйте изображение в формате JPG или PNG</Typography.Text>
        <div className={'d-flex gap-16'}>
          <Upload accept=".jpeg,.jpg,.png" customRequest={customRequest} showUploadList={false}>
            <Button type="primary" icon={<UploadOutlined />}>
              Загрузить новую
            </Button>
          </Upload>
        </div>
      </div>
    </div>
  );
};
