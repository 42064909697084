import { useCallback } from 'react';
import useAxios from 'axios-hooks';
import { SelectOptions } from '../components/DebouncedSelect/DebouncedSelect';
import { TOTAL_COUNT_10 } from 'utils/constants';

type EntityWithIdOrValue =
  | {
      id: number;
    }
  | {
      value: string;
    };

const useAsyncSelectOptions = <T extends EntityWithIdOrValue>(
  url: string,
  mapper: (array: Array<T>) => SelectOptions,
  selectedIds?: number[],
) => {
  const [{ loading: isFetching }, getOptions] = useAxios<BaseResponse<Array<T>>>(
    {
      url: url,
      method: 'GET',
    },
    { manual: true },
  );

  const fetcher = useCallback(
    async (searchQuery?: string, page = 1): Promise<SelectOptions> => {
      try {
        const { data } = await getOptions({
          url: `${url}${searchQuery}&offset=${page * TOTAL_COUNT_10}`,
        });

        const filteredData =
          selectedIds && selectedIds?.length > 0
            ? data.data.filter((item) => !selectedIds.includes('id' in item ? item.id : +item.value))
            : data.data;

        return mapper(filteredData);
      } catch (e) {
        return [];
      }
    },
    [getOptions, mapper, url, selectedIds],
  );

  return {
    fetcher,
    isFetching,
  };
};

export default useAsyncSelectOptions;
