import React, { useState } from 'react';
import { Form, Radio } from 'antd';
import { AsyncParentPlaceSelect } from 'components/AsyncPlaceSelect';
import { DadataSelect } from 'components/DadataSelect';
import { PlaceFrontend } from 'client/mappers/place';
import { CitySelect } from 'components/CitySelect';

const radioValues = {
  city: 'city',
  address: 'address',
  place: 'place',
} as const;

const radioButtons = [
  { value: radioValues.city, title: 'Город' },
  { value: radioValues.address, title: 'Адрес' },
  { value: radioValues.place, title: 'Место' },
];

type Tab = (typeof radioValues)[keyof typeof radioValues];

type PlaceSwitcherProps = {
  initialTab: Tab;
  initialParent: PlaceFrontend['parent'];
  selectFormItemName?: string;
  enableCitySelect?: boolean;
  currentPlaceId?: number;
  showCity?: boolean;
};

export const PlaceSwitcher: React.FC<PlaceSwitcherProps> = ({
  initialTab,
  initialParent,
  selectFormItemName = 'parent',
  enableCitySelect = true,
  currentPlaceId,
  showCity = false,
}) => {
  const [radio, setRadio] = useState<Tab>(initialTab);

  const selectMap = {
    address: <DadataSelect enableCitySelect={enableCitySelect} />,
    place: (
      <AsyncParentPlaceSelect
        initialParent={initialParent}
        formItemName={selectFormItemName}
        currentPlaceId={currentPlaceId}
      />
    ),
    city: <CitySelect showCheckbox isMultiple={true} />,
  };

  return (
    <>
      <Form.Item name="location" label="Расположение">
        <Radio.Group value={radio} className="d-flex flex-grow-1" onChange={(e) => setRadio(e.target.value)}>
          {radioButtons
            .filter(({ value }) => showCity || value !== radioValues.city)
            .map(({ value, title }) => (
              <Radio.Button key={value} className="flex-grow-1 text-align-center" value={value}>
                {title}
              </Radio.Button>
            ))}
        </Radio.Group>
      </Form.Item>
      {selectMap[radio]}
    </>
  );
};
