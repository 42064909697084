import { ClientRole } from 'client/hooks/socialRoles';
import {
  AdminAuthorResponseDto,
  AdminAuthorRolesListResponseDto,
  AdminCitiesAreasResponseDto,
  AuthorVideoResponse,
  Role,
  RolesListResponse,
} from 'models';
import { FrontendAuthorItem } from 'pages/Author/types';

export const getNewAuthorDefaultData = (): FrontendAuthorItem => ({
  id: NaN,
  news: [],
  events: [],
  videos: [],
  services: [],
  recommendedEvents: [],
  places: [],
  name: '',
  description: '',
  announcement: '',
  image: '',
  roles: [],
  showInTagCloud: false,
  showInRecommendation: false,
  noShowInMain: false,
  createdAt: '',
  isTopAuthor: false,
  priority: 30,
});

export const mapAuthorForFrontend = (data: AdminAuthorResponseDto): FrontendAuthorItem => {
  const {
    id,
    name,
    announcement,
    description,
    image,
    isTopAuthor,
    showInTagCloud,
    showInRecommendation,
    noShowInMain,
    createdAt,
    priority,
    roles,
    news,
    events,
    videos,
    places,
    services,
    recommendedEvents,
  } = data;
  return {
    id,
    name,
    announcement,
    description,
    image,
    priority,
    isTopAuthor,
    showInTagCloud,
    showInRecommendation,
    noShowInMain,
    createdAt,
    roles: rolesMapper(roles),
    news: news?.length ? baseBindingMapper(news) : undefined,
    events: events?.length ? baseBindingMapper(events) : undefined,
    videos: videos?.length ? bindingVideosMapper(videos) : undefined,
    places: places?.length ? baseBindingMapper(places) : undefined,
    services: services?.length ? baseBindingMapper(services) : undefined,
    recommendedEvents: recommendedEvents?.length ? baseBindingMapper(recommendedEvents) : undefined,
  };
};

export const mapAuthorForBackend = (formFields: FrontendAuthorItem) => {
  const {
    name,
    announcement,
    description,
    isTopAuthor,
    showInTagCloud,
    showInRecommendation,
    noShowInMain,
    priority,
    news,
    events,
    recommendedEvents,
    services,
    places,
    roles,
    videos,
  } = formFields;

  return {
    name,
    announcement,
    description,
    priority,
    roles: roles?.map((role) => {
      return role.key;
    }),
    events: events?.length
      ? events.map((event) => {
          return +event.value;
        })
      : undefined,
    services: services?.length
      ? services.map((service) => {
          return +service.value;
        })
      : undefined,
    news: news?.length
      ? news.map((_new) => {
          return +_new.value;
        })
      : undefined,
    places: places?.length
      ? places.map((place) => {
          return +place.value;
        })
      : undefined,
    isTopAuthor,
    recommendedEvents: recommendedEvents?.length
      ? recommendedEvents.map((recEvent) => {
          return +recEvent.value;
        })
      : undefined,
    noShowInMain,
    showInRecommendation,
    showInTagCloud,
    videos: videos?.length
      ? videos?.map((video) => {
          return video.value;
        })
      : undefined,
  };
};

export const baseBindingMapper = <T extends { title?: string; id: number }>(
  data?: Array<T>,
  selectedIds?: number[],
) => {
  const filteredData =
    selectedIds && selectedIds?.length > 0 ? data?.filter((item) => !selectedIds.includes(item.id)) : data;
  return filteredData?.map((item) => {
    return {
      label: item.title && item.title.length > 50 ? `${item.title.slice(0, 50)}...` : item.title || '',
      value: item.id,
    };
  });
};

export const bindingVideosMapper = (data?: Array<AuthorVideoResponse>, selectedIds?: number[]) => {
  const filteredData =
    selectedIds && selectedIds?.length > 0 ? data?.filter((item) => !selectedIds.includes(item.id)) : data;
  return filteredData?.map((item) => {
    return {
      label: item.title.length > 50 ? `${item.title.slice(0, 50)}...` : item.title,
      value: item.url,
      key: item.id,
    };
  });
};

export const baseAuthorBindingMapper = (data?: AdminAuthorRolesListResponseDto[], selectedIds?: number[]) => {
  const filteredData =
    selectedIds && selectedIds?.length > 0 ? data?.filter((item) => !selectedIds.includes(item.id)) : data;
  return filteredData?.map((item) => {
    return {
      label: item.name.length > 50 ? `${item.name.slice(0, 50)}...` : item.name,
      value: item.id,
    };
  });
};

export const rolesMapper = (data?: RolesListResponse['data'] | ClientRole[] | Role[]) => {
  return data?.map((item) => {
    return {
      label: item.name,
      key: item.id,
      value: item.name,
    };
  });
};

export const mapCities = (data?: AdminCitiesAreasResponseDto[]) => {
  return data?.map(({ name, id }) => {
    return { label: name, key: id, value: name };
  });
};
