import { EntityHeader } from '../../components/EntityHeader';
import React, { useState } from 'react';
import { Checkbox, Form, Layout, notification, Tabs } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { cityTabKeys, cityTabs, TAB_PREFIX, useTabs } from '../../hooks/useTabs';
import { useSearchParams } from 'react-router-dom';
import { AdminCitiesAreasResponse, AdminCitiesAreasResponseDto } from '../../models';
import { FormHeader } from '../../components/FormHeader';
import { FormProviderProps } from 'antd/lib/form/context';
import { StyledInput } from '../../components/StyledInputs/StyledInput';
import { showFormErrorMessages } from '../../utils/showFormErrorMessages';
import { CityImage } from './CityImage';
import { RcFile } from 'antd/es/upload';

type Props = {
  cityData: AdminCitiesAreasResponse;
  onSave: (newDataForSave: AdminCitiesAreasResponse, file?: string | Blob | RcFile) => void;
  isCreationMode: boolean;
  onDeleteButtonClick: () => void;
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
export const CityBaseInfoForm: React.FC<{ initialValues: Exclude<AdminCitiesAreasResponseDto, 'id'> }> = ({
  initialValues,
}) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} initialValues={initialValues} {...layout} name="base-info">
      <Form.Item name="name" label="Город" rules={[{ required: true }]}>
        <StyledInput />
      </Form.Item>
      <Form.Item valuePropName="checked" name="isPopular" wrapperCol={{ offset: 8, span: 16 }}>
        <Checkbox>Является популярным</Checkbox>
      </Form.Item>
    </Form>
  );
};

export const CityContent: React.FC<Props> = ({ cityData, onSave, isCreationMode, onDeleteButtonClick }) => {
  const [searchParams] = useSearchParams();
  const initialTab = searchParams.get(TAB_PREFIX)?.toString();
  const { activeTab, tabs, setActiveTab } = useTabs(cityTabs, initialTab);
  const [imageFile, setImageFile] = useState<string | Blob | RcFile | null>(null);

  const newInitialValues = { ...cityData };

  const tabContent = {
    [cityTabKeys.common]: <CityBaseInfoForm initialValues={newInitialValues} />,
    [cityTabKeys.gallery]: (
      <CityImage
        image={cityData.image}
        cityId={cityData.id}
        isCreationMode={isCreationMode}
        setImageFile={setImageFile}
      />
    ),
  };

  const onFormFinish: FormProviderProps['onFormFinish'] = async (_, { forms }) => {
    const errorMessage = () => notification.error({ message: 'Необходимо заполнить все поля корректно' });
    try {
      const validation = await Promise.allSettled(Object.values(forms).map((f) => f.validateFields()));

      const hasInvalidValues = validation.find((v) => v.status === 'rejected');

      if (!hasInvalidValues) {
        const data = {};
        Object.entries(forms).forEach(([_, form]) => {
          Object.assign(data, form.getFieldsValue());
        });
        onSave(data as AdminCitiesAreasResponse, imageFile as string | Blob | RcFile);
      } else {
        showFormErrorMessages(validation);
      }
    } catch (error) {
      errorMessage();
    }
  };

  return (
    <Form.Provider onFormFinish={onFormFinish}>
      <Layout>
        <EntityHeader
          title={cityData.name || 'Новый город'}
          onArchiveButtonClick={!isCreationMode ? onDeleteButtonClick : undefined}
        />
        <Tabs items={tabs} activeKey={activeTab} onChange={setActiveTab} className="bg-neutral service-tabs pl-24" />
        <Content className="mt-40 p-16 rounded-16 bg-neutral">
          {tabs.map(({ key, label, contentClassName, formHeaderType }) => {
            return (
              <div key={key} className={contentClassName}>
                <FormHeader title={label} type={formHeaderType} />
                <div>{tabContent[key]}</div>
              </div>
            );
          })}
        </Content>
      </Layout>
    </Form.Provider>
  );
};
