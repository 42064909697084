import { Form, Layout, notification, Typography } from 'antd';
import { FormProviderProps } from 'antd/lib/form/context';
import { Content } from 'antd/lib/layout/layout';
import { useSocialRoles } from 'client/hooks/socialRoles';
import { ContactsForm } from 'forms/ContactsForm';
import { OurReviewForm } from 'forms/OurReviewForm';
import { ScheduleForm } from 'forms/ScheduleForm';
import { FC, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { EntityHeader } from '../../components/EntityHeader';
import { FormHeader } from '../../components/FormHeader';
import { Gallery } from '../../components/Gallery';
import { TabsWithButtons } from '../../components/TabsWithButtons/TabsWithButtons';
import { SocialRolesForm } from '../../forms/SocialRolesForm';
import { eventTabKeys, eventTabs, TAB_PREFIX, useTabs } from '../../hooks/useTabs';
import { ImageEntity, ImageType } from '../../hooks/useUploadImage';
import { showFormErrorMessages } from '../../utils/showFormErrorMessages';
import { EventRelatedPlacesAndEvents } from './components/EventRelatedPlacesAndEvents';
import { EventRelatedPlacesAndEventsGlobal } from './components/EventRelatedPlacesAndEventsGlobal';
import { EventContentProps, EventFormValues } from './Event.types';
import { BaseEventForm } from './forms/BaseEventForm';
import { RelatedCardData } from './forms/BaseEventForm/BaseEventForm.typedef';
import { DatesAndTicketsForm } from './forms/DatesAndTicketsForm';
import { InfoForSiteForm } from './forms/InfoForSiteForm';
import { TicketsForm } from './forms/TicketsForm';
import { useFormats, useThematics } from 'client/hooks/events';
import { mapDadataResponse } from 'utils/formatters/mapDadataResponse';

const getTabs = (isNewEvent: boolean, isGlobal: boolean) => {
  const tabs = isNewEvent
    ? eventTabs.filter(
        ({ key }) => ![eventTabKeys.review, eventTabKeys.gallery, eventTabKeys.relatedPlacesAndEvents].includes(key),
      )
    : eventTabs;
  if (isGlobal) return tabs.filter(({ key }) => key !== eventTabKeys.datesAndTickets);
  return tabs;
};

export const EventContent: FC<EventContentProps> = ({
  onSubmit,
  event: { baseInfo, infoForSite, image, datesAndTickets, gallery, ourReview, contacts, needs, children, id, tickets },

  onArchive,
}) => {
  const [searchParams] = useSearchParams();
  const initialTab = searchParams.get(TAB_PREFIX)?.toString();
  const needsProps = useSocialRoles(needs ?? []);

  const isGlobalEvent = baseInfo.status === 'global';

  const { activeTab, tabs, setActiveTab, goToNextTab, goToPrevTab } = useTabs(
    getTabs(isNaN(+id), isGlobalEvent),
    initialTab,
  );

  const { thematics } = useThematics(activeTab === eventTabKeys.siteData);
  const { formats } = useFormats(activeTab === eventTabKeys.siteData);

  const [favoriteImage, setFavoriteImage] = useState(image);

  const eventRelatedCardMapper = (ev: RelatedCardData) => {
    return {
      id: ev.id,
      title: ev.name,
      description: ev.address,
      imageUrl: ev.imageUrl,
    };
  };

  const mappedChildrenEvents = (events: Array<RelatedCardData>) => {
    return events.map((item) => {
      return eventRelatedCardMapper(item);
    });
  };

  const defineScheduleType = () => {
    switch (baseInfo.frequency) {
      case 'one-time':
        return <DatesAndTicketsForm {...datesAndTickets} />;
      case 'regular':
        return <ScheduleForm {...datesAndTickets} />;
      default:
        return null;
    }
  };

  const tabContent = {
    [eventTabKeys.common]: <BaseEventForm initialValues={baseInfo} id={id} />,
    [eventTabKeys.siteData]: (
      <InfoForSiteForm
        thematics={mapDadataResponse(thematics)}
        formats={mapDadataResponse(formats)}
        initialValues={infoForSite}
      />
    ),
    [eventTabKeys.tickets]: <TicketsForm {...tickets} />,
    [eventTabKeys.datesAndTickets]: defineScheduleType(),
    [eventTabKeys.gallery]: (
      <Gallery
        gallery={gallery ?? []}
        title="Галерея события"
        entity={ImageEntity.Events}
        imageType={ImageType.Gallery}
        onStarClick={setFavoriteImage}
        favoriteImage={favoriteImage ?? ''}
      />
    ),
    [eventTabKeys.roles]: <SocialRolesForm isActiveForm={activeTab === eventTabKeys.roles} {...needsProps} />,
    [eventTabKeys.contacts]: (
      <ContactsForm initialValues={contacts} withLegalAddress isSiteRequired={false} isLegalAddressRequired={false} />
    ),
    [eventTabKeys.review]: <OurReviewForm initialValue={ourReview} />,
    [eventTabKeys.relatedPlacesAndEvents]: isGlobalEvent ? (
      <EventRelatedPlacesAndEventsGlobal isActive={activeTab === eventTabKeys.relatedPlacesAndEvents} placeId={id} />
    ) : (
      <EventRelatedPlacesAndEvents
        place={baseInfo.place && eventRelatedCardMapper(baseInfo.place)}
        parentCard={baseInfo.parent && eventRelatedCardMapper(baseInfo.parent)}
        address={baseInfo.address}
      >
        {children ? mappedChildrenEvents(children) : []}
      </EventRelatedPlacesAndEvents>
    ),
  };
  if (isGlobalEvent) {
    delete tabContent[eventTabKeys.datesAndTickets];
  }
  const filteredTabs = tabs.filter((item) => {
    if (isGlobalEvent) {
      return item.key !== String(eventTabKeys.datesAndTickets);
    }
    return item;
  });

  const onFormFinish: FormProviderProps['onFormFinish'] = async (_, { forms }) => {
    const isCancelled = searchParams.get('cancelled');
    const status = searchParams.get('status');
    const errorMessage = () => notification.error({ message: 'Необходимо заполнить все поля корректно' });

    try {
      const validation = await Promise.allSettled(Object.values(forms).map((f) => f.validateFields()));

      const hasInvalidValues = validation.find((v) => v.status === 'rejected');
      if (!hasInvalidValues) {
        // TODO: Если передать на бэк isCanceled false, то статус события не изменится.
        const data = {
          needs: needsProps.needs,
          favoriteImage: favoriteImage,
          isCancelled: isCancelled !== 'false' ? Boolean(isCancelled) : undefined,
          periodicity: id !== 'new' ? baseInfo.status : status,
        } as EventFormValues;

        Object.entries(forms).forEach(([name, form]) => {
          const fieldsValue = form.getFieldsValue();
          if (name === 'schedule') {
            Object.assign(data, {
              days: fieldsValue.days,
              aroundTheClock: fieldsValue.aroundTheClock,
              noSchedule: fieldsValue.noSchedule,
            });
          } else {
            Object.assign(data, form.getFieldsValue());
          }
        });

        onSubmit(data);
      } else {
        showFormErrorMessages(validation);
      }
    } catch (error) {
      errorMessage();
    }
  };

  const formHeaderTitle = useMemo(() => {
    if (isGlobalEvent) {
      return 'Новое глобальное событие';
    }
    if (baseInfo.status === 'once') {
      return 'Новое разовое событие';
    }
    if (baseInfo.status === 'regular') {
      return 'Новое регулярное событие';
    }
    return 'Новое событие';
  }, [baseInfo, isGlobalEvent]);

  return (
    <Layout>
      <Form.Provider onFormFinish={onFormFinish}>
        <div>
          <EntityHeader
            title={id !== 'new' ? baseInfo.shortTitle : formHeaderTitle}
            onArchiveButtonClick={isNaN(Number(id)) ? undefined : onArchive}
          />
          <TabsWithButtons
            filteredTabs={filteredTabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            onLeftButtonClick={goToPrevTab}
            onRightButtonClick={goToNextTab}
          />
          <Content
            className={`mt-40 p-16 rounded-16 ${activeTab !== eventTabKeys.relatedPlacesAndEvents ? 'bg-neutral' : ''}`}
          >
            {filteredTabs.map(({ key, label, formHeaderType, contentClassName }) => {
              return (
                <div className={contentClassName} key={key}>
                  {activeTab !== eventTabKeys.relatedPlacesAndEvents ? (
                    <FormHeader title={label} type={formHeaderType} />
                  ) : (
                    <div className="p-16 rounded-16 bg-neutral">
                      <Content className="p-16 pl-40">
                        <Typography.Title className="m-0" level={3}>
                          {label}
                        </Typography.Title>
                      </Content>
                    </div>
                  )}
                  <div>{tabContent[key]}</div>
                </div>
              );
            })}
          </Content>
          {/*<Content className="mt-40 p-16 rounded-16 bg-neutral">
            {filteredTabs.map(({ key, label, formHeaderType, contentClassName }) => {
              return (
                <div className={contentClassName} key={key}>
                  <FormHeader title={label} type={formHeaderType} />
                  <div>{tabContent[key]}</div>
                </div>
              );
            })}
          </Content>*/}
        </div>
      </Form.Provider>
    </Layout>
  );
};
