import { CityContent } from './CityContent';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import { ClientErrorException } from '../../components/ClientErrorException';
import { useCitiesBackendActions } from 'client/hooks/cities';

export const City = () => {
  const { id } = useParams<{ id: string }>();
  const isCreationMode = id === 'new';

  const {
    info: { cityData, error, loading, isLoading },
    actions: { onSave, handleDeleteCity },
  } = useCitiesBackendActions({ isCreationMode, id });

  if (error) {
    return <ClientErrorException errorCode={error.request.status} />;
  }

  return (
    <Spin spinning={isLoading || loading}>
      {cityData && !loading && (
        <CityContent
          onDeleteButtonClick={handleDeleteCity}
          isCreationMode={isCreationMode}
          cityData={cityData}
          onSave={onSave}
        />
      )}
    </Spin>
  );
};
