import { RcFile } from 'antd/es/upload';
import useAxios from 'axios-hooks';
import { ImageEntity, useEntityUploadSingleImage } from 'hooks/useUploadImage';
import { FC, useState } from 'react';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { cityByIdEndpointUrl } from 'client/endpoints/cities';
import { AdminCitiesGetOneAreaResponse } from 'models';
import { UploadImage } from 'components/UploadImage';

type CityImageProps = {
  image: string | null;
  cityId: number;
  isCreationMode?: boolean;
  setImageFile: (file: string | Blob | RcFile) => void;
};

export const CityImage: FC<CityImageProps> = ({ image: logo, cityId, isCreationMode, setImageFile }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [logoImage, setLogoImage] = useState<{ src?: string }>({
    src: logo ?? undefined,
  });

  const getCity = useAxios<BaseResponse<AdminCitiesGetOneAreaResponse>>(cityByIdEndpointUrl(cityId.toString()), {
    manual: true,
  })[1];

  const customRequest = useEntityUploadSingleImage(ImageEntity.Cities, async () => {
    const city = await getCity();

    setLogoImage({ src: city.data.data.image || undefined });
    setIsLoading(false);
  });

  const customRequestHandler = (params: UploadRequestOption) => {
    if (isCreationMode) {
      setImageFile(params.file);
      setLogoImage({ src: URL.createObjectURL(params.file as Blob) });
    } else {
      setIsLoading(true);
      customRequest(params);
    }
  };

  return <UploadImage isLoading={isLoading} customRequest={customRequestHandler} src={logoImage.src} />;
};
