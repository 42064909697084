import { Spin } from 'antd';
import { FC, UIEvent, useEffect, useMemo, useRef, useState } from 'react';
import { DefaultOptionType, SelectProps } from 'antd/lib/select';
import debounce from 'lodash.debounce';
import { StyledSelectComponent } from '../StyledSelect';
import { TOTAL_COUNT_10 } from 'utils/constants';

export type SelectOptions = SelectProps['options'];

export type DebouncedSelectProps = {
  debounceTimeout?: number;
  initialOptions?: SelectOptions;
  fetcher: (value?: string, page?: number) => Promise<SelectOptions>;
  resetOptions?: boolean;
  currentPlaceId?: number;
} & SelectProps;

export const DebouncedSelect: FC<DebouncedSelectProps> = ({
  fetcher,
  initialOptions = [],
  debounceTimeout = 800,
  labelInValue = false,
  resetOptions = false,
  currentPlaceId,
  ...props
}) => {
  const parsedInitialOptions = currentPlaceId
    ? initialOptions.filter((item) => item.value !== currentPlaceId)
    : initialOptions;
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<DefaultOptionType[]>(parsedInitialOptions);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [hasLoadedAllOptions, setHasLoadedAllOptions] = useState(false);

  const fetchRef = useRef(0);

  const initialFetcher = () => {
    setOptions(parsedInitialOptions);
    setPage(1);
    setHasLoadedAllOptions(false);
    setSearchQuery('');
  };

  const fetchNextPageOptions = async () => {
    setFetching(true);

    const nextPage = page + 1;

    const newOptions = (await fetcher(searchQuery, page)) ?? [];

    setOptions((prevOptions) => {
      return [...prevOptions, ...newOptions];
    });

    setPage(nextPage);

    if (newOptions.length < TOTAL_COUNT_10) {
      setHasLoadedAllOptions(true);
    }

    setFetching(false);
  };

  const handleChangeDropdownVisible = (isOpen: boolean) => {
    if (isOpen) {
      initialFetcher();
    }
  };

  const handleScrollPopup = async (event: UIEvent<HTMLDivElement>) => {
    const popupElm = event.target as HTMLDivElement;

    const hasFullyScrolled = popupElm.scrollHeight - popupElm.scrollTop === popupElm.offsetHeight;

    if (hasFullyScrolled && !hasLoadedAllOptions && !fetching) {
      await fetchNextPageOptions();
    }
  };

  useEffect(() => {
    // Reset options when resetOptions prop changes
    if (resetOptions) {
      setOptions(parsedInitialOptions);
      setPage(1);
      setHasLoadedAllOptions(false);
      setSearchQuery('');
    }
  }, [resetOptions, parsedInitialOptions]);

  const debounceFetcher = useMemo(() => {
    const loadOptions = async (value: string) => {
      setSearchQuery(value);
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      if (value.length < 1) {
        if (currentPlaceId) setOptions(parsedInitialOptions);
        else setOptions(parsedInitialOptions);
        setFetching(false);
        return;
      }
      const newOptions = await fetcher(value, 0);
      if (fetchId !== fetchRef.current) {
        // for fetch callback order
        return;
      }

      if (newOptions !== undefined) {
        if (currentPlaceId) setOptions(newOptions.filter((item) => item.value !== currentPlaceId));
        else setOptions(newOptions);
      }

      setFetching(false);
    };

    return debounce(loadOptions, debounceTimeout);
  }, [debounceTimeout, fetcher, parsedInitialOptions, currentPlaceId]);

  return (
    <StyledSelectComponent
      maxLength={100}
      onSearch={debounceFetcher}
      labelInValue={labelInValue}
      filterOption={false}
      onDropdownVisibleChange={handleChangeDropdownVisible}
      showSearch
      optionLabelProp="label"
      onPopupScroll={handleScrollPopup}
      notFoundContent={
        fetching ? <Spin size="small" /> : <p className="p-0 m-0">По вашему запросу ничего не найдено</p>
      }
      options={options}
      {...props}
    />
  );
};
