import { RcFile } from 'antd/es/upload';
import useAxios from 'axios-hooks';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import React, { useState } from 'react';
import { getAuthorByIdEndpointUrl } from '../../client/endpoints/authors';
import { ImageEntity, useEntityUploadSingleImage } from '../../hooks/useUploadImage';

import { UploadImage } from 'components/UploadImage';

type Props = {
  image: string | null;
  authorId: number;
  isCreationMode?: boolean;
  setImageFile: (file: string | Blob | RcFile) => void;
};

export const AuthorImage: React.FC<Props> = ({ image: logo, authorId, isCreationMode, setImageFile }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [logoImage, setLogoImage] = useState<{ src?: string }>({
    src: logo ?? undefined,
  });

  const getAuthor = useAxios(getAuthorByIdEndpointUrl(authorId.toString()), { manual: true })[1];

  const customRequest = useEntityUploadSingleImage(ImageEntity.Authors, async () => {
    const author = await getAuthor();
    setLogoImage({ src: author.data.data.image });
    setIsLoading(false);
  });

  const customRequestHandler = (params: UploadRequestOption) => {
    if (isCreationMode) {
      setImageFile(params.file);
      setLogoImage({ src: URL.createObjectURL(params.file as Blob) });
    } else {
      setIsLoading(true);
      customRequest(params);
    }
  };

  return <UploadImage isLoading={isLoading} customRequest={customRequestHandler} src={logoImage.src} />;
};
