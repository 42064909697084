import { useCallback, useContext, useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import { UserContext } from 'contexts/UserContext';
import { notification } from 'antd';
import { AdminCitiesAreasResponse, AdminCitiesAreasResponseDto } from 'models';
import { useDebounce } from 'hooks';
import { getCitiesEndpointUrl } from 'client/endpoints/cities';

export type PaginationParams = {
  limit: number;
  offset: number;
};

export const useCities = () => {
  const [paginationData, setPaginationData] = useState<PaginationParams>({
    limit: 10,
    offset: 0,
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const debouncedQuery = useDebounce(searchQuery, 500);
  const isAuthorized = typeof useContext(UserContext).user !== 'undefined';
  const searchQueryParam = debouncedQuery.length >= 1 ? `&query=${debouncedQuery}` : '';

  const [{ loading: isCitiesLoading }, getCities] = useAxios<BaseResponse<AdminCitiesAreasResponse[], PageMeta>>(
    {
      url: getCitiesEndpointUrl() + `limit=${paginationData.limit}&offset=${paginationData.offset}${searchQueryParam}`,
      method: 'GET',
    },
    { manual: true },
  );
  const [cities, setCities] = useState<Array<AdminCitiesAreasResponse>>([]);

  const getInitialCities = useCallback(async () => {
    try {
      const {
        data: { data: cities, meta },
      } = await getCities();
      setTotalCount(meta.total ?? 0);
      setCities(cities);
    } catch (e) {
      notification.error({ message: 'Что-то пошло не так.' });
    }
  }, [getCities]);

  const updatePaginationInfo = useCallback(({ limit, offset }: PaginationParams) => {
    setPaginationData({ limit: limit, offset: offset });
  }, []);

  const updateSearchQuery = (query: string) => {
    setSearchQuery(query);
  };
  const addCity = useCallback(
    (city: AdminCitiesAreasResponse) => {
      setCities([...cities, city]);
    },
    [cities],
  );

  const changeCity = useCallback(
    (city: AdminCitiesAreasResponseDto) => {
      const mappedCities = cities.map((item) => {
        if (city.id.toString() === item.id.toString()) {
          return { ...item, name: city.name };
        }
        return item;
      });

      setCities(mappedCities);
    },
    [cities],
  );

  const removeCity = useCallback(
    (cityId: AdminCitiesAreasResponseDto['id']) => {
      const filteredCities = cities.filter((item) => {
        return item.id.toString() !== cityId.toString();
      });
      setCities(filteredCities);
      // setPaginationData({ offset: 0, limit: 10 });
    },
    [cities],
  );

  useEffect(() => {
    if (isAuthorized) {
      getInitialCities();
    }
  }, [isAuthorized, getCities, cities.length, searchQuery, paginationData, getInitialCities]);

  return {
    addCity,
    removeCity,
    changeCity,
    getCities: getInitialCities,
    cities,
    isLoading: isCitiesLoading,
    updatePaginationInfo,
    totalCount,
    updateSearchQuery,
  };
};
