import { RcFile } from 'antd/es/upload';
import { ImageEntity, useEntityUploadSingleImage } from '../../hooks/useUploadImage';
import React, { useState } from 'react';
import useAxios from 'axios-hooks';
import { getNewDataByIdEndpointUrl } from '../../client/endpoints/news';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { UploadImage } from 'components/UploadImage';

type Props = {
  image: string | null;
  newId: number;
  isCreationMode?: boolean;
  setImageFile: (file: string | Blob | RcFile) => void;
};

export const NewItemImage: React.FC<Props> = ({ image: logo, newId, isCreationMode, setImageFile }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [logoImage, setLogoImage] = useState<{ src?: string }>({
    src: logo ?? undefined,
  });

  const getNew = useAxios(getNewDataByIdEndpointUrl(newId.toString()), { manual: true })[1];

  const customRequest = useEntityUploadSingleImage(ImageEntity.News, async () => {
    const newItem = await getNew();
    setLogoImage({ src: newItem.data.data.image });
    setIsLoading(false);
  });

  const customRequestHandler = (params: UploadRequestOption) => {
    if (isCreationMode) {
      setImageFile(params.file);
      setLogoImage({ src: URL.createObjectURL(params.file as Blob) });
    } else {
      setIsLoading(true);
      customRequest(params);
    }
  };

  return <UploadImage isLoading={isLoading} customRequest={customRequestHandler} src={logoImage.src} />;
};
