import useAxios from 'axios-hooks';
import { TagDto } from 'models';
import { Index } from 'client/endpoints';

export type TThematics = { thematics: TagDto[]; refetchThematics: () => void };

/**
 * @param enabled Флаг, который указывает, нужно ли выполнять запрос на получение тематик
 */

export const useThematics = (enabled = true): TThematics => {
  const [{ data }, refetchThematics] = useAxios<BaseResponse<TagDto[]>>(
    { url: Index.adminEvents + '/info/thematics', method: 'GET' },
    { manual: !enabled },
  );

  return { thematics: data?.data || [], refetchThematics };
};
