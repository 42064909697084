import { Checkbox, Form } from 'antd';
import { DebouncedSelect } from 'components/DebouncedSelect';
import useAsyncSelectOptions from 'hooks/useAsyncSelectOptions';
import { AdminCitiesAreasResponseDto } from 'models';
import { FC } from 'react';
import { getCitiesEndpointWithSearchUrl } from '../../client/endpoints/cities';
import { useCitiesEntity } from 'client/hooks/cities';
import { DefaultOptionType } from 'antd/lib/select';

type Props = {
  onCitySelect?: (option: DefaultOptionType) => void;
  isMultiple?: boolean;
  showCheckbox?: boolean;
};

export const CitySelect: FC<Props> = ({ onCitySelect, isMultiple = true, showCheckbox = false }) => {
  const mapDadataResponse = (data?: AdminCitiesAreasResponseDto[]) => {
    return data?.map(({ name, id }) => {
      return { label: name, key: id, value: name };
    });
  };

  const { data: cities } = useCitiesEntity();

  const mappedCities = mapDadataResponse(cities);

  const { fetcher } = useAsyncSelectOptions(getCitiesEndpointWithSearchUrl(), mapDadataResponse);

  return (
    <>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => prevValues.linkAllAreas !== currentValues.linkAllAreas}
      >
        {({ getFieldValue }) =>
          !getFieldValue('linkAllAreas') ? (
            <Form.Item rules={[{ required: true }]} name="area" label="Город">
              <DebouncedSelect
                fetcher={fetcher}
                onSelect={(_, option) => {
                  onCitySelect && onCitySelect(option);
                }}
                labelInValue={true}
                initialOptions={mappedCities}
                mode={isMultiple ? 'multiple' : undefined}
              />
            </Form.Item>
          ) : null
        }
      </Form.Item>
      {showCheckbox && (
        <Form.Item required name={'linkAllAreas'} valuePropName="checked" wrapperCol={{ offset: 9, span: 16 }}>
          <Checkbox defaultChecked={false}>Все города</Checkbox>
        </Form.Item>
      )}
    </>
  );
};
