import { Spin } from 'antd';
import { VideoContent } from 'pages/Video/VideoContent';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ClientErrorException } from '../../components/ClientErrorException';
import { mapVideoForFrontend } from 'client/mappers/video';
import { useVideosBackendActions } from 'client/hooks/videos';

export const VideoItem = () => {
  const { id } = useParams<{ id: string }>();

  const {
    info: { videoData, videoError, videoLoading, tagsError, tagsLoading, isLoading, tags },
    actions: { onSave },
  } = useVideosBackendActions({ id });

  const error = videoError || tagsError;
  const loading = isLoading || videoLoading || tagsLoading;

  if (error) {
    return <ClientErrorException errorCode={error.request.status} />;
  }

  return (
    <Spin spinning={loading}>
      {videoData && tags && !loading && (
        <VideoContent videoData={mapVideoForFrontend(videoData, tags)} onSave={onSave} />
      )}
    </Spin>
  );
};
