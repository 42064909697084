import { CloseOutlined, FlagOutlined, HomeOutlined } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, Form, InputProps, Layout, Pagination, Row, Spin } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/lib/layout/layout';
import { Moment } from 'moment';
import React, { ChangeEvent, CSSProperties, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Index } from '../../client/endpoints';
import { AsyncPlaceSelect } from '../../components/AsyncPlaceSelect';
import { ListCard } from '../../components/ListCard';
import { NotFoundSearchResults } from '../../components/NotFoundSearchResults';
import { PageTitle } from '../../components/PageTitle';
import { StyledInput } from '../../components/StyledInputs/StyledInput';
import { useDebounce, useListing } from '../../hooks';
import { EventListDto } from '../../models';
import { NAVIGATION } from '../../paths';
import { translatePeriodicity } from '../../utils';
import { EventTypeSelect } from 'pages/Event/components/EventTypeSelect';
import { CreateEventModal } from './components/CreateEventModal';

const iconStyles: CSSProperties = { color: '#8C8C8C', marginRight: 6 };

export const Events = () => {
  const push = useNavigate();
  const [form] = useForm();
  const [searchType, setSearchType] = useState('');
  const [searchName, setSearchName] = useState('');
  const [placeId, setPlaceId] = useState<null | number>(null);
  const [date, setDate] = useState<Moment | null>(null);
  const [checkedFilter, setCheckedFilter] = useState<Record<'once' | 'draft', boolean>>({ once: false, draft: false });
  const [eventType, setEventType] = useState<string | null>(null);
  const debouncedSearchName = useDebounce(searchName, 1000);

  // TODO: Надо вынести в utils.ts внутри этой же папки
  const getEventsUrl = () => {
    let route = `${Index.adminEvents}?`;
    if (date !== null) {
      // Мы устанавливаем время на 9:00 часов, т.к. поиск на бэке чувствителен к часам/минутам.
      const dateNew = date.toDate();
      dateNew.setHours(9, 0, 0, 0);
      const updatedDateNew = `${dateNew.getFullYear()}-${('0' + (dateNew.getMonth() + 1)).slice(-2)}-${(
        '0' + dateNew.getDate()
      ).slice(-2)}T09:00:00.000Z`;
      route += `date=${updatedDateNew}&`;
    }
    if (debouncedSearchName.length >= 3) {
      route += `title=${debouncedSearchName}&`;
    }
    if (checkedFilter.once) {
      route += `onlyOneTime=true&`;
    }
    if (checkedFilter.draft) {
      route += `onlyExternal=true&`;
    }
    if (placeId !== null) {
      route += `placeId=${placeId}&`;
    }
    if (eventType !== null) {
      route += `type=${eventType}&`;
    }
    return route;
  };
  const { data, paginationProps, loading } = useListing<EventListDto & { description: string }>(getEventsUrl());

  const [isModalOpen, setIsModalOpen] = useState(false);

  const clickEventCard = (id: number) => {
    push(`${NAVIGATION.events}/${id}`);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleSearchTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchName(e.target.value);
  };

  const handleSelectDate = (value: Moment | null) => {
    setDate(value);
  };

  const handleSelectPlace = (place: number) => {
    setPlaceId(place);
  };
  const handleSelectEventType = (value: string) => {
    setEventType(value);
  };
  const searchInputs: (InputProps & { label?: string; type: string })[] = [
    {
      value: searchName,
      label: 'Название события',
      onChange: handleSearchTitle,
      prefix: <FlagOutlined style={iconStyles} />,
      type: 'title',
    },
    {
      type: 'place',
    },
    {
      value: searchType,
      label: 'Дата',
      prefix: <HomeOutlined style={iconStyles} />,
      type: 'date',
    },
    {
      value: searchType,
      prefix: <HomeOutlined style={iconStyles} />,
      type: 'dropdownSelect',
    },
  ];

  const handleClearButtonClick = () => {
    form.setFieldValue('eventType', null);
    form.setFieldValue('parent', null);
    setSearchType('');
    setSearchName('');
    setEventType(null);
    setCheckedFilter({ once: false, draft: false });
    setPlaceId(null);
    setDate(null);
  };

  const changeSelectedCheckbox = (e: CheckboxChangeEvent) => {
    const value = e.target.value as keyof typeof checkedFilter;
    setCheckedFilter((prev) => ({ ...prev, [value]: !prev[value] }));
  };

  const isEventsListNotEmpty = !loading && data?.meta.total && data.data.length >= 1;

  return (
    <Layout>
      <CreateEventModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <PageTitle title="События" wrapperClassName="mb-24" onAddButtonClick={openModal} />
      <Content>
        <div className="p-24 mt-40 bg-neutral mb-24 rounded-8">
          <Form form={form} className="d-flex gap-24" layout="vertical">
            {searchInputs.map(({ label, type, ...inputProps }) => (
              <Form.Item key={type} className="flex-grow-1 m-0" label={label}>
                {type === 'place' && (
                  <AsyncPlaceSelect required={false} onSelect={handleSelectPlace} placeholder="поиск от 3 символов" />
                )}
                {type === 'date' && (
                  <DatePicker picker={'date'} onChange={handleSelectDate} value={date} className="w-100" />
                )}
                {type === 'dropdownSelect' && (
                  <EventTypeSelect onSelect={handleSelectEventType} value={eventType} required={false} />
                )}
                {type === 'title' && <StyledInput {...inputProps} placeholder="поиск от 3 символов" />}
              </Form.Item>
            ))}
          </Form>
          <div className="d-flex flex-justify-between mt-8">
            <div className="mt-24 d-flex">
              <Checkbox checked={checkedFilter.once} value={'once'} onChange={changeSelectedCheckbox}>
                {'Разовое'}
              </Checkbox>
              <Checkbox checked={checkedFilter.draft} value={'draft'} onChange={changeSelectedCheckbox}>
                {'Неопубликованное'}
              </Checkbox>
            </div>
            <Button className="mt-24" onClick={handleClearButtonClick} danger type="link" icon={<CloseOutlined />}>
              Очистить всё
            </Button>
          </div>
        </div>
        <Row className="gap-16" justify={loading ? 'center' : undefined}>
          {data && data.data.length < 1 && !loading && <NotFoundSearchResults />}
          {data === undefined || loading ? (
            <Spin className="flex-justify-center" size="large" />
          ) : (
            <Row className="gap-16">
              {data.data.map((item) => {
                return (
                  <ListCard
                    key={item.id}
                    title={item.title}
                    description={translatePeriodicity(item.periodicity)}
                    imageUrl={item.image || ''}
                    onClick={() => {
                      return clickEventCard(item.id);
                    }}
                  />
                );
              })}
            </Row>
          )}
        </Row>

        {isEventsListNotEmpty ? (
          <div className="d-flex flex-justify-end">
            <Pagination
              {...paginationProps}
              total={data.meta.total}
              pageSizeOptions={[8, 12, 16, 20]}
              defaultCurrent={paginationProps.current}
            />
          </div>
        ) : null}
      </Content>
    </Layout>
  );
};
